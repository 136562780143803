<template>
  <div id="user-view">
    <v-row v-if="author && author.id">
      <v-col cols="12">
        <v-progress-linear
          v-if="loading"
          indeterminate
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-card

          style="height: 100%"
          class=""
        >
          <v-card-title>
            {{ $t("Auteur") }}
            <v-spacer />
            <v-btn
              icon
              x-small
              @click="openDialog"
            >
              <v-icon
                color="primary"
                small
              >
                {{ mdiPencil }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="text--primary">
            <v-row no-gutters>
              <v-col
                cols="12"
                class="d-flex justify-start"
              >
                <span>{{ $t("Nom") }} :</span>
                <div class="text--primary font-weight-semibold ml-2">
                  {{ author.full_name }}
                </div>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-start"
              >
                <span>{{ $t("Adresse") }} :</span>
                <div class="text--primary font-weight-semibold ml-2">
                  {{ author.addresses.map((e) => e.address ).join(";") }}
                </div>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-start"
              >
                <span>{{ $t("Adresses emails") }} :</span>
                <div class="text--primary font-weight-semibold ml-2">
                  {{ author.email?author.email.join('/ '):'' }}
                </div>
              </v-col>
              <v-col
                v-if="formatPhoneNumber(author).length > 3"
                cols="12"
                class="d-flex justify-start"
              >
                <span>{{ $t("Téléphones") }} :</span>
                <div class="text--primary font-weight-semibold ml-2">
                  {{ formatPhoneNumber(author) }}
                </div>
              </v-col>
              <v-col
                v-if="author.source"
                cols="12"
                class="d-flex justify-start"
              >
                <span>{{ $t("Source") }} :</span>
                <div class="text--primary font-weight-semibold ml-2">
                  {{ author.source ? author.source.source_name : '-' }}
                </div>
              </v-col>
              <v-col
                v-if="author.status"
                cols="12"
                class="d-flex justify-start"
              >
                <span>{{ $t("Statut") }} :</span>
                <div class="text--primary font-weight-semibold ml-2">
                  <v-chip
                    small
                    :color="author.status? author.status.color: ''"
                  >
                    {{ author.status ? author.status.name : '-' }}
                  </v-chip>
                </div>
              </v-col>
              <v-col
                v-if="author.author_ref"
                cols="12"
                class="d-flex justify-start"
              >
                <span>{{ $t("Référence") }} :</span>
                <div class="text--primary font-weight-semibold ml-2">
                  {{ author.author_ref }}
                </div>
              </v-col>
              <v-col
                v-if="author.compte_auteur"
                cols="12"
                class="d-flex justify-start"
              >
                <span>{{ $t("Compte auteur") }} :</span>
                <div class="text--primary font-weight-semibold ml-2">
                  {{ author.compte_auteur }}
                </div>
              </v-col>
              <v-col
                v-if="author.profession"
                cols="12"
                class="d-flex "
              >
                <span>{{ $t("Profession") }} :</span>
                <div class="text--primary font-weight-semibold ml-2">
                  {{ author.profession ? author.profession.label:'' }}
                </div>
              </v-col>
              <v-col
                cols="12"
                class="d-flex "
              >
                <span class="shrink text-no-wrap">{{ $t("Complétion du profil") }} :</span>
                <div class="text--primary font-weight-semibold ml-2 d-flex align-center" style="width: 200px">

                  <v-progress-linear
                    :value="author.progress || 0"
                    :color="getAuthorColor()"
                    height="15"
                    rounded
                  >
                    <span class=" " >{{ (author.progress || 0).toFixed(0) }}%</span>
                  </v-progress-linear>
                </div>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <author-state
          v-model="author" @refresh-data="fetchItem" />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <author-networks
          v-model="author"
          @refresh-data="fetchItem"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <author-events
          v-model="author"
          @refresh-data="fetchItem"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <author-awards
          v-model="author"
          @refresh-data="fetchItem"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <author-salons
          v-model="author"
          @refresh-data="fetchItem"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <author-sessions
          v-model="author"
          @refresh-data="fetchItem"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <author-publications
          v-model="author"
          @refresh-data="fetchItem"
        />
      </v-col>



    </v-row>
    <v-row v-else>
      <v-col cols="12" sm="6">
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" sm="6">
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" sm="6">
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col cols="12" sm="6">
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 800 : '100%'"
    >
      <author-form
        v-model="author"
        :is-form-active="isFormActive"
        :with-addresses="true"
        @refresh-data="fetchItem"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{
            author.id ? $t('Modifier') : $t('Ajouter un auteur')
          }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template #form-cancel>
          <v-btn
            :loading="loading"
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </author-form>
    </v-dialog>

  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiLockOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiLinkVariant,
  mdiAccountCogOutline,
  mdiBookAccountOutline,
  mdiBookMultipleOutline,
  mdiCity,
  mdiDelete,
  mdiPencil,
  mdiClose,
  mdiCalendarOutline,
  mdiFileDocumentEditOutline,
} from '@mdi/js'
import router from '@/router'
import store from '@/store'

// eslint-disable-next-line object-curly-newline
import AuthorBioPanel from './author-bio-panel/AuthorBioPanel.vue'
import authorStoreModule from '../authorStoreModule'
import AuthorTabOverview from './author-tab-overview/AuthorTabOverview.vue'
import AuthorTabSecurity from './author-tab-security/AuthorTabSecurity.vue'

import AccountSettingsAccount from '@/views/pages/account-settings/AccountSettingsAccount.vue'
import Activities from '@/views/apps/author/author-view/author-tab-overview/Activities'
import KnowledgeBase from '@/views/pages/knowledge-base/KnowledgeBase'
import Addresses from '@/views/apps/author/author-list/Addresses'
import Manuscripts from '@/views/apps/manuscript/Manuscripts'
import Publications from '@/views/apps/publications/Publications'
import AuthorForm from '@/views/apps/author/author-list/AuthorForm.vue'
import {avatarText, formatDate } from '../../../../@core/utils/filter'
import AuthorNetworks from '@/views/apps/author/author-view/components/AuthorNetworks.vue'
import controller from '../author-list/useAuthorsList'
import AuthorEvents from '@/views/apps/author/author-view/components/AuthorEvents.vue'
import AuthorSalons from '@/views/apps/author/author-view/components/AuthorSalons.vue'
import AuthorSessions from '@/views/apps/author/author-view/components/AuthorSessions.vue'
import AuthorPublications from '@/views/apps/author/author-view/components/AuthorPublications.vue'
import AuthorState from "@/views/apps/author/author-view/components/AuthorState.vue";
import AuthorAwards from "@/views/apps/author/author-view/components/AuthorAwards.vue";

export default {
  components: {
    AuthorAwards,
    AuthorState,
    AuthorPublications,
    AuthorSessions,
    AuthorSalons,
    AuthorEvents,
    AuthorNetworks,
    AuthorForm,
  },
  methods: { formatDate },
  setup() {
    const {
      downloadObject,
      tableColumns,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      loading,
      options,
      authorTotalLocal,
      selectedRows,
      snackBarMessage,
      isSnackbarBottomVisible,

      deleteItem,
      confirmDelete,
      deleteDialog,
      fetchAuthors,
      resolveAuthorRoleIcon,
      resolveAuthorTotalIcon,
      resolveAuthorStatusVariant,
      resolveAuthorStatusName,
      resolveAuthorRoleVariant,
    } = controller()

    const USER_APP_STORE_MODULE_NAME = 'app-author'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, authorStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const author = ref({})
    const isFormActive = ref(false)
    const authorTab = ref(null)
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)
    store.dispatch('app/fetchNetworks', { per_page: 500 })
    store.dispatch('app/fetchEventTypes', { per_page: 500 })
    store.dispatch('app/fetchProfessions', { per_page: 500 })
    store.dispatch('app/fetchMediaTypes', { per_page: 500 })
    store.dispatch('app/fetchAuthorStates', { per_page: 500 })
    store.dispatch('app/fetchAuthorTypes', { per_page: 500 })

    const fetchItem = () => {
      loading.value = true
      store
        .dispatch('app-author/fetchAuthor', { id: router.currentRoute.params.id })
        .then(response => {
          loading.value = false
          author.value = response.data
        })
        .catch(error => {
          loading.value = false
          if (error.response.status === 404) {
            author.value = {}
          }
        })
    }
    const getAuthorColor = () => {
      if (!author.value.progress) return 'error'

      if (author.value.progress < 25) {
        return 'error'
      }
      if (author.value.progress <= 75) {
        return 'warning'
      }

      return 'success'
    }

    const plansList = [
      { text: 'Basic - $0/month', value: 'basic' },
      { text: 'Standard - $99/month', value: 'standard' },
      { text: 'Enterprise - $499/month', value: 'enterprise' },
      { text: 'Company - $999/month', value: 'company' },
    ]

    const tabs = [
      { icon: mdiAccountOutline, title: 'Historique des activités' },
      { icon: mdiAccountCogOutline, title: 'Paramètres' },
      { icon: mdiLockOutline, title: 'Sécurité' },

      /* { icon: mdiBookmarkOutline, title: 'Billing & Plan' },
      { icon: mdiBellOutline, title: 'Notifications' },
      { icon: mdiLinkVariant, title: 'Connections' }, */
    ]

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    const formatPhoneNumber = val => {
      if (val.country_id == (store.state.app.settings.default_country_id || 79)) {
        // let p = val.phone
        // var string = "02076861111"
        let string = val.phone
        if ((!string || !string.trim().length) && val.addresses) {
          return val.addresses.map(a => formatPhoneNumber(a)).join(', ')
        }
        if (string && string.length < 9) {
          string = `0${string}`
        }

        if (string) {
          if (string.length > 7 && string.length < 10) {
            const phone = string.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, '$1.$2.$3.$4')

            return phone
          }
          if (string.length > 9) {
            const phone = string.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1.$2.$3.$4.$5')

            return phone
          }

          const phone = string.replace(/(\d{2})(\d{2})(\d{7})/, '$1.$2.$3')

          return phone
        }

        return val.phone
      }

      return val.phone
    }

    const openDialog = () => {
      isFormActive.value = true
    }

    fetchItem()

    return {
      isFormActive,
      tabs,
      authorTab,
      plansList,
      selectedPlan,
      isPlanUpgradeDialogVisible,
      author,
      loading,
      fetchAuthors,
      fetchItem,
      openDialog,
      AuthorTabOverview,
      resolveCurrentPlanValue,
      mdiAccountOutline,
      mdiAccountCogOutline,
      mdiLockOutline,
      mdiBookAccountOutline,
      mdiBookMultipleOutline,
      mdiCity,
      mdiDelete,
      mdiPencil,
      mdiClose,
      mdiCalendarOutline,
      mdiFileDocumentEditOutline,
      formatPhoneNumber,
      avatarText,
      getAuthorColor,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
